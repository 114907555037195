<template>
  <div id="app">
    <PC v-if="mainWidth >= 900" />
    <H5 v-else />
  </div>
</template>

<script>
import PC from './components/PC.vue'
import H5 from './components/H5.vue'

export default {
  name: 'App',
  components: {
    PC,
    H5
  },
  data() {
    return {
      mainWidth: document.body.clientWidth
    }
  },
  created() {
    console.log(this.mainWidth)
  }
}
</script>

<style lang="less">
#app {
  width: 100%;
}
</style>
