var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contain"},[_vm._m(0),_c('div',{staticClass:"main"},[_c('img',{staticClass:"img-banner",attrs:{"src":require("../assets/h5images/img-main.png"),"alt":"banner"}}),_c('ul',{staticClass:"list-link"},[_c('li',{on:{"click":_vm.ChangePage}},[_vm._m(1),_vm._m(2)]),_c('li',{on:{"click":_vm.ChangePage}},[_vm._m(3),_vm._m(4)]),_c('li',{on:{"click":_vm.ChangePage}},[_vm._m(5),_vm._m(6)]),_c('li',{on:{"click":_vm.ChangePage}},[_vm._m(7),_vm._m(8)]),_c('li',{on:{"click":_vm.ChangePage}},[_vm._m(9),_vm._m(10)]),_c('li',{on:{"click":_vm.ChangePage}},[_vm._m(11),_vm._m(12)])]),_vm._m(13),_c('img',{staticClass:"img-tit",attrs:{"src":require("../assets/h5images/img-node.png")}}),_c('p',{staticClass:"footer"},[_vm._v("Copyright © 澳門金沙 Reserved")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('header',[_c('a',{attrs:{"target":"_blank"}},[_c('img',{attrs:{"src":require("../assets/h5images/logo.png"),"alt":"Logo"}})]),_c('img',{staticClass:"img-weba",attrs:{"src":require("../assets/h5images/head-right.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"l-li"},[_c('img',{staticClass:"icon-contry",attrs:{"src":require("../assets/h5images/map-cn.png")}}),_c('span',{staticClass:"mar15px"},[_vm._v("中国大陆")]),_c('img',{staticClass:"marr30px icon-arrow",attrs:{"src":require("../assets/h5images/icon-line.png")}}),_c('span',[_vm._v("检测完毕")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btn-li"},[_c('img',{attrs:{"src":require("../assets/h5images/btn-enter.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"l-li"},[_c('img',{staticClass:"icon-contry",attrs:{"src":require("../assets/h5images/map-hk.png")}}),_c('span',{staticClass:"mar15px"},[_vm._v("中国香港")]),_c('img',{staticClass:"marr30px icon-arrow",attrs:{"src":require("../assets/h5images/icon-line.png")}}),_c('span',[_vm._v("检测完毕")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btn-li"},[_c('img',{attrs:{"src":require("../assets/h5images/btn-enter.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"l-li"},[_c('img',{staticClass:"icon-contry",attrs:{"src":require("../assets/h5images/map-macau.png")}}),_c('span',{staticClass:"mar15px"},[_vm._v("中国澳门")]),_c('img',{staticClass:"marr30px icon-arrow",attrs:{"src":require("../assets/h5images/icon-line.png")}}),_c('span',[_vm._v("检测完毕")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btn-li"},[_c('img',{attrs:{"src":require("../assets/h5images/btn-enter.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"l-li"},[_c('img',{staticClass:"icon-contry",attrs:{"src":require("../assets/h5images/map-taiwan.png")}}),_c('span',{staticClass:"mar15px"},[_vm._v("中国台湾")]),_c('img',{staticClass:"marr30px icon-arrow",attrs:{"src":require("../assets/h5images/icon-line.png")}}),_c('span',[_vm._v("检测完毕")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btn-li"},[_c('img',{attrs:{"src":require("../assets/h5images/btn-enter.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"l-li"},[_c('img',{staticClass:"icon-contry",attrs:{"src":require("../assets/h5images/map-asia.png")}}),_c('span',{staticClass:"mar15px"},[_vm._v("亚洲路线")]),_c('img',{staticClass:"marr30px icon-arrow",attrs:{"src":require("../assets/h5images/icon-line.png")}}),_c('span',[_vm._v("检测完毕")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btn-li"},[_c('img',{attrs:{"src":require("../assets/h5images/btn-enter.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"l-li"},[_c('img',{staticClass:"icon-contry",attrs:{"src":require("../assets/h5images/map-inter.png")}}),_c('span',{staticClass:"mar15px"},[_vm._v("国际路线")]),_c('img',{staticClass:"marr30px icon-arrow",attrs:{"src":require("../assets/h5images/icon-line.png")}}),_c('span',[_vm._v("检测完毕")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btn-li"},[_c('img',{attrs:{"src":require("../assets/h5images/btn-enter.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btn-content"},[_c('a',{attrs:{"href":"http://29908.com/","target":"_blank"}},[_c('img',{attrs:{"src":require("../assets/h5images/btn-ios.png")}})]),_c('a',{attrs:{"href":"https://lwesoes.l0hv76mnpf.com/71bcfb4d41cb4cff7b54jkfle-keli14bec0f0b040d000b09080b040808020902050608010f81ed0c0940c147fc795","target":"_blank"}},[_c('img',{attrs:{"src":require("../assets/h5images/btn-service.png")}})]),_c('a',{attrs:{"href":"http://29908.com/","target":"_blank"}},[_c('img',{attrs:{"src":require("../assets/h5images/btn-and.png")}})])])
}]

export { render, staticRenderFns }