<template>
    <div class="pc">
        <header class="clearfix">
            <div class="header-main">
                <a class="left-header"><img src="../assets/images/logo.png" alt="Logo"></a>
                <img src="../assets/images/head-right.png" alt="Title" class="right-header">
            </div>
        </header>
        <div class="main">
            <div class="mian-mian clearfix">
                <img src="../assets/images/img-main.png" class="img-headlm">
                <div class="right-main">
                    <img src="../assets/images/img-node.png" alt="Title" class="img-headrm">
                    <ul class="list-link">
                        <li @click="onChangePage">
                            <div class="l-li"><img src="../assets/images/map-cn.png"><span
                                    class="mar15px">中国大陆</span><img src="../assets/images/icon-line.png"
                                    class="marr30px"><span>检测完毕</span></div>
                            <div class="btn-li"><img src="../assets/images/btn-enter.png"></div>
                        </li>
                        <li @click="onChangePage">
                            <div class="l-li"><img src="../assets/images/map-hk.png"><span
                                    class="mar15px">中国香港</span><img src="../assets/images/icon-line.png"
                                    class="marr30px"><span>检测完毕</span></div>
                            <div class="btn-li"><img src="../assets/images/btn-enter.png"></div>
                        </li>
                        <li @click="onChangePage">
                            <div class="l-li"><img src="../assets/images/map-macau.png"><span
                                    class="mar15px">中国澳门</span><img src="../assets/images/icon-line.png"
                                    class="marr30px"><span>检测完毕</span></div>
                            <div class="btn-li"><img src="../assets/images/btn-enter.png"></div>
                        </li>
                        <li @click="onChangePage">
                            <div class="l-li"><img src="../assets/images/map-taiwan.png"><span
                                    class="mar15px">中国台湾</span><img src="../assets/images/icon-line.png"
                                    class="marr30px"><span>检测完毕</span></div>
                            <div class="btn-li"><img src="../assets/images/btn-enter.png"></div>
                        </li>
                        <li @click="onChangePage">
                            <div class="l-li"><img src="../assets/images/map-asia.png"><span
                                    class="mar15px">亚洲路线</span><img src="../assets/images/icon-line.png"
                                    class="marr30px"><span>检测完毕</span></div>
                            <div class="btn-li"><img src="../assets/images/btn-enter.png"></div>
                        </li>
                        <li @click="onChangePage">
                            <div class="l-li"><img src="../assets/images/map-inter.png"><span
                                    class="mar15px">国际路线</span><img src="../assets/images/icon-line.png"
                                    class="marr30px"><span>检测完毕</span></div>
                            <div class="btn-li"><img src="../assets/images/btn-enter.png"></div>
                        </li>
                    </ul>
                    <div class="btn-content"><a
                            href="https://lwesoes.l0hv76mnpf.com/71bcfb4d41cb4cff7b54jkfle-keli14bec0f0b040d000b09080b040808020902050608010f81ed0c0940c147fc795"
                            target="_blank"><img src="../assets/images/btn-service.png"></a><a href="http://29908.com/"
                            target="_blank"><img src="../assets/images/btn-download.png"></a></div>
                </div>
            </div>
        </div>
        <footer>Copyright © 澳門金沙 Reserved</footer>
    </div>
</template>

<script>
export default {
    name: 'pc',
    data() {
        return {
        }
    },
    mounted() {
        setInterval(() => {
            console.log(123)
            this.changeCheck()
        }, 3000)
    },
    methods: {
        changeCheck() {
            let list = document.querySelectorAll('.check')
            list.forEach(item => {
                item.innerHTML = this.getRandom(30, 60)
            })
        },
        getRandom(n, m) {
            var num = Math.floor(Math.random() * (m - n + 1) + n)
            return num
        },
        onChangePage() {
            let index = this.getRandom(0, 7)
            let list = [
                'https://www.299000001.com',
                'https://www.299000002.com',
                'https://www.299077000.com',
                'https://66.203.151.126:882/xefdwmqyrl/lqyqcmurwprwyjiheilxgfzemxiscurwhansirsxlygofkewlb.php',
                'https://134.122.219.234:131/cuivxcekcf/pdccauwloxwjtvbfuujsnuncmmoylwymgvkwbbpkkebspmtegu.php',
                'https://www.11772990.com',
                'https://66.203.151.126:882/xefdwmqyrl/lqyqcmurwprwyjiheilxgfzemxiscurwhansirsxlygofkewlb.php',
                'https://www.299055222.com',
            ]
            window.open(list[index], '_blank')
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
header {
    width: 100%;
    height: 96px;
    background: #af0000;
    min-width: 1200px;
}

.header-main {
    width: 1200px;
    margin: 0 auto;
    height: 100%;
}

.left-header {
    float: left;
    margin-top: 8px;
    display: block;
}

.right-header {
    float: right;
    display: block;
    margin-top: 15px;
}

.main {
    height: 100vh;
    top: 96px;
    padding-top: 60px;
    position: fixed;
    background: url(../assets/images/bg.jpg) center top no-repeat;
    width: 100%;
    min-width: 1200px;
}

.mian-mian {
    width: 1200px;
    margin: 0 auto;
    position: relative;
}

.img-headlm {
    margin-left: -200px;
}

.right-main {
    width: 612px;
    position: absolute;
    right: -100px;
    top: 0;
}

.img-headrm {
    display: block;
    width: 503px;
    margin: 0 auto 25px;
}

.list-link {
    width: 100%;

}

.list-link li {
    display: flex;
    justify-content: space-between;
    width: 601px;
    height: 48px;
    margin: 0 0 10px 5px;
    background: url("../assets/images/bg-border.png") left top no-repeat;
    align-items: center;
    cursor: pointer;
}

.l-li {
    display: flex;
    flex: 1;
    align-items: center;
    font-size: 20px;
    margin-left: 22px;
}

.mar15px {
    margin: 0 34px 0 10px;
}

.marr30px {
    margin-right: 30px;
}

.btn-li {
    width: 212px;
    height: 40px;
    margin: 0 6px 0 10px;
}

.btn-content {
    margin-top: 15px;
}

footer {
    font-size: 16px;
    width: 100%;
    text-align: center;
    background: none;
    position: absolute;
    left: 0;
    bottom: 5vh;
}
</style>